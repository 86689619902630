import image1 from "../images/image1.jpg";
import image2 from "../images/image2.jpg";
import image3 from "../images/image3.jpg";

export const data = [
    {
        id: 1,
        img: image1,
        Description: "SatyaSreeman",
    },
    {
        id: 2,
        img: image2,
        Description: "SatyaSreeman",
    },
    {
        id: 3,
        img: image3,
        Description: "SatyaSreeman",
    },
    {
        id: 4,
        img: image3,
        Description: "SatyaSreeman",
    },
    {
        id: 5,
        img: image3,
        Description: "SatyaSreeman",
    },
    {
        id: 6,
        img: image3,
        Description: "SatyaSreeman",
    },
    {
        id: 7,
        img: image1,
        Description: "SatyaSreeman",
    },
    {
        id: 8,
        img: image2,
        Description: "SatyaSreeman",
    },
    {
        id: 9,
        img: image3,
        Description: "SatyaSreeman",
    },
    {
        id: 10,
        img: image3,
        Description: "SatyaSreeman",
    },
    {
        id: 11,
        img: image3,
        Description: "SatyaSreeman",
    },
    {
        id: 12,
        img: image3,
        Description: "SatyaSreeman",
    },
]